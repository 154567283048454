import React, {
    createRef,
    createContext,
    useContext,
    useState,
    useEffect,
} from 'react';

import { useScrollContext } from 'components/ScrollProvider';

const defaultContext = { mounted: false, visible: false, focal: false };

const BoundingContext = createContext(defaultContext);

export const BoundingBox = ({ children, onFocalChange }) => {
    const ref = createRef();
    const { top: scrollTop } = useScrollContext();

    const [bounding, setBounding] = useState(defaultContext);

    useEffect(() => {
        const { current } = ref;

        if (current) {
            const { top, bottom } = current.getBoundingClientRect();

            const visible = top < window.innerHeight && bottom > 0;
            const center = top + (bottom - top) / 2 - window.innerHeight / 2;

            // The box is "focal" if the vertical center of the screen falls between its top and bottom.
            //
            // NOTE: This logic is very specific and should probably be made more
            // flexible, but we'll tackly what if/when necessary.
            const focal =
                top < window.innerHeight / 2 && bottom > window.innerHeight / 2;

            if (
                typeof onFocalChange === 'function' &&
                focal !== bounding.focal
            ) {
                onFocalChange(focal);
            }

            setBounding(prev => ({
                ...prev,
                mounted: true,
                visible,
                focal,
                top,
                bottom,
                center,
            }));
        }
    }, [scrollTop]);

    return (
        <div ref={ref} css={{ position: 'relative' }}>
            <BoundingContext.Provider value={bounding}>
                {children}
            </BoundingContext.Provider>
        </div>
    );
};

export function useBoundingContext() {
    return useContext(BoundingContext);
}

export default BoundingBox;
