import React from 'react';

import { createAnchor } from 'components/Navigation';

import WearableCompliance from 'components/WearableCompliance';
import Narrative from 'components/Narrative';
import NarrativeStatic from 'components/NarrativeStatic';

export const PlatformOverview = ({
    heading,
    anchor,
    intro,
    call,
    narrative,
}) => {
    const ref = createAnchor(anchor.slug);
    const contentRef = createAnchor('platform-content');

    return (
        <div id="platform" ref={ref}>
            <WearableCompliance heading={heading} intro={intro} call={call} />
            <div ref={contentRef}>
                <NarrativeStatic content={narrative} />
                <Narrative content={narrative} />
            </div>
        </div>
    );
};

export default PlatformOverview;
