import React from 'react';
import get from 'lodash/get';

import './NarrativeStatic.scss';

export const NarrativeStatic = ({ content }) => (
    <section className="narrative-section-static">
        {content.map(({ heading, text, backgroundImage, call }, index) => (
            <div key={index} className="card">
                <img src={get(backgroundImage, 'file.url')} />
                <div className="card-info">
                    <h3>{heading}</h3>
                    <p>{text}</p>
                    {call && (
                        <a className="cta" href={get(call, 'href')}>
                            {get(call, 'label')}
                        </a>
                    )}
                </div>
            </div>
        ))}
    </section>
);

export default NarrativeStatic;
