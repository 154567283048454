import React, { createRef, useState, useEffect } from 'react';
import { motion } from 'framer-motion';
import get from 'lodash/get';

import { createAnchor, useNavigation } from 'components/Navigation';
import { useScrollContext } from 'components/ScrollProvider';
import BoundingBox, { useBoundingContext } from 'components/BoundingBox';
import PaginationBullet from 'components/PaginationBullet';

import './Narrative.scss';

const frameStyle = {
    position: 'relative',
    zIndex: 0,
    height: '66.7vw',
    backgroundPosition: 'center',
    backgroundSize: 'cover',
};

const FloatingNarrator = ({ focal = 0, contents = [] }) => {
    const ref = createRef();
    const [height, setHeight] = useState(0);
    const {
        top: boundingTop = 0,
        bottom: boundingBottom,
    } = useBoundingContext();

    useEffect(() => {
        const { current } = ref;

        if (current) {
            const { top, bottom } = current.getBoundingClientRect();

            setHeight(bottom - top);
        }
    }, []);

    const middle = typeof window === 'undefined' ? 0 : window.innerHeight / 2;

    let position = 'fixed';
    let top = middle - height / 2;
    let bottom;

    console.log('Top:', middle, height, top, boundingTop);

    if (boundingTop > top) {
        position = 'absolute';
        top = 0;
    } else if (boundingBottom && top > boundingBottom - height) {
        position = 'absolute';
        top = undefined;
        bottom = 0;
    }

    return (
        <div
            className="narrative-section"
            ref={ref}
            css={{
                height: '66.7vw',
                width: '35vw',
                zIndex: 5,
                left: '5vw',
            }}
            style={{
                opacity: height === 0 ? 0 : 1,
                position,
                top,
                bottom,
            }}
        >
            <div
                css={{
                    position: 'absolute',
                    right: '100%',
                    top: '50%',
                    padding: '0 10px',
                    transform: 'translate(0, -50%)',
                }}
            >
                {contents.map((val, index) => {
                    const { anchors } = useNavigation();
                    const { scrollTo } = useScrollContext();

                    return (
                        <PaginationBullet
                            key={index}
                            active={index === focal}
                            click={() =>
                                anchors[val.id] &&
                                scrollTo(anchors[val.id].anchorTop)
                            }
                        />
                    );
                })}
            </div>
            <div
                css={{
                    position: 'absolute',
                    top: '50%',
                    left: 0,
                    width: '100%',
                    height: '30vh',
                    maxHeight: '100vh',
                    transform: 'translate(0, -50%)',
                }}
            >
                {contents.map(({ heading, text, call }, index) => (
                    <motion.div
                        key={index}
                        css={{
                            backgroundColor: '#FFF',
                            boxShadow: '0 0 3.125rem 0 rgba(0,0,0,0.25)',
                            borderRadius: '3px',
                            position: 'absolute',
                            top: '50%',
                            left: 0,
                            width: '100%',
                            transform: 'translate(0, -50%)',
                            padding: '3rem',
                        }}
                        style={{ zIndex: index === focal ? 10 : 0 }}
                        initial="hidden"
                        animate={index === focal ? 'visible' : 'hidden'}
                        variants={{
                            visible: {
                                opacity: 1,
                                transition: {
                                    duration: 0.2,
                                },
                            },
                            hidden: {
                                opacity: 0,
                                transition: {
                                    duration: 0.2,
                                },
                            },
                        }}
                    >
                        <h1>{heading}</h1>
                        <h2>{text}</h2>
                        {call && (
                            <a className="cta" href={call.href}>
                                {call.label}
                            </a>
                        )}
                    </motion.div>
                ))}
            </div>
        </div>
    );
};

const Blur = ({ src }) => {
    const { mounted, center = 0 } = useBoundingContext();

    return (
        <motion.div
            css={{
                position: 'absolute',
                top: 0,
                left: 0,
                right: 0,
                bottom: 0,
                zIndex: 0,
                backgroundPosition: 'center',
                backgroundSize: 'cover',
                overflow: 'hidden',
            }}
            style={{
                backgroundImage: `url(${src})`,
            }}
            initial="hidden"
            animate={mounted && Math.abs(center) < 300 ? 'visible' : 'hidden'}
            variants={{ visible: { opacity: 1 }, hidden: { opacity: 0 } }}
        />
    );
};

const Overlay = ({ style, src }) => {
    const { mounted, center = 0 } = useBoundingContext();
    return (
        <motion.img
            className="overlay-image"
            style={style}
            src={src}
            initial="hidden"
            animate={mounted && Math.abs(center) < 500 ? 'visible' : 'hidden'}
            variants={{ visible: { opacity: 1 }, hidden: { opacity: 0 } }}
        />
    );
};

export const Narrative = ({ content }) => {
    const [focal, setFocal] = useState(0);

    return (
        <BoundingBox>
            <FloatingNarrator focal={focal} contents={content} />
            <section className="narrative-section" id="narrative">
                {content.map((data, index) => {
                    const {
                        id,
                        backgroundImage,
                        backgroundImageBlurred,
                        overlayImage,
                    } = data;

                    const anchor = createAnchor(id);

                    return (
                        <BoundingBox
                            key={index}
                            onFocalChange={value => value && setFocal(index)}
                        >
                            <div ref={anchor} css={{ position: 'relative' }}>
                                <div
                                    css={{
                                        ...frameStyle,
                                        overflow: 'hidden',
                                    }}
                                    style={{
                                        backgroundImage: `url(${get(
                                            backgroundImage,
                                            'file.url',
                                        )})`,
                                    }}
                                >
                                    <Blur
                                        src={get(
                                            backgroundImageBlurred,
                                            'file.url',
                                        )}
                                    />
                                    <div className="overlay-container">
                                        <Overlay
                                            src={get(overlayImage, 'file.url')}
                                        />
                                    </div>
                                </div>
                            </div>
                        </BoundingBox>
                    );
                })}
            </section>
        </BoundingBox>
    );
};

export default Narrative;
